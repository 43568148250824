<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Bancos</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            v-if="roles.includes(user.type)"
            class="ma-2 white--text"
            @click="addBank"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Nuevo banco
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="getHeaders"
          :items="banks"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :sort-by="['createdAt']"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.country`]="{ item }">
            {{ item.countryName }}
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              small
              :loading="loading"
              @click="editBank(item)"
              class="ml-3 white--text"
              color="primary"
            >
              Editar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog scrollable persistent v-if="addDialog" v-model="addDialog" max-width="900px">
      <add
        @cancel="addDialog = false"
        :selectedItem="selectedItem"
        :loading="loading"
        @success="handleSuccess"
      />
    </v-dialog>
  </v-container>
</template>
  
  <script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import add from "./add";

import { mapState } from "vuex";

export default {
  name: "cities",
  components: {
    lottie: Lottie,
    add,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      banks: [],
      addDialog: false,
      selectedItem: null,
      cardDialog: false,
      roles: ["sudo", "admin", "EFCM", "AC"],
      headers: [
        {
          text: "Nombre",
          value: "name",
        },

        {
          text: "Fecha de creación",
          value: "createdAt",
        },

        {
          value: "options",
          align: "end",
          width: "400px",
          roles: ["sudo", "admin", "EFCM", "AC"],
        },
      ],
    };
  },

  computed: {
    ...mapState(["user"]),
    getHeaders() {
      return this.headers.filter(
        (i) => !i.roles || (i.roles && i.roles.includes(this.user.type))
      );
    },
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) {
        return "no disponible";
      }

      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  methods: {
    handleSuccess() {
      this.addDialog = false;
      this.snackbarText = "Información modificada exitosamente.";
      this.snackbar = true;
    },
    showCards(e) {
      this.cardDialog = true;
      this.selectedItem = e;
    },
    addBank() {
      this.addDialog = true;
      this.selectedItem = null;
    },

    editBank(e) {
      this.addDialog = true;
      this.selectedItem = e;
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "banco");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding("banks", db.collection("banks"));

    this.loading = false;
  },
};
</script>
  
  <style>
.select-city {
  border-radius: 50px;
  padding: 0;
}
</style>
  