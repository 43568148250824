<template>
  <v-card>
    <v-snackbar color="red" top absolute :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="grid-close">
      <span class="headline">Nuevo banco</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        v-if="!loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                outlined
                :loading="loading"
                :rules="[rules.required]"
                rounded
                label="Nombre del banco"
                v-model="item.name"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <h2 style="color: #FF1744">Bines</h2>

              <v-row style="align-items: center">
                <v-col cols="9" sm="9" md="9">
                  <v-text-field
                    hide-details
                    label="Buscar"
                    filled
                    v-model="search"
                  ></v-text-field>
                </v-col>
                <v-col
                  style="justify-content: flex-end; display: flex"
                  cols="3"
                  sm="3"
                  md="3"
                >
                  <v-btn @click="newCard()" class="ma-0" color="primary">
                    <v-icon class="mr-3">fas fa-plus</v-icon> Nueva
                    tarjeta</v-btn
                  >
                </v-col>
              </v-row>

              <v-data-table
                class="mt-10"
                :headers="headers"
                :items="getBines"
                :search="search"
                :items-per-page="5"
              >
                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    small
                    :loading="loading"
                    @click="deleteBin(item)"
                    class="ml-3 white--text"
                    color="red darken-4"
                  >
                    Eliminar
                  </v-btn>

                  <v-btn
                    small
                    :loading="loading"
                    @click="editCard(item)"
                    class="ml-3 white--text"
                    color="primary"
                  >
                    Editar
                  </v-btn>
                </template></v-data-table
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions
      class="pa-3"
      style="display: flex; justify-content: flex-end"
    >
      <v-btn
        @click="selectedItem ? update() : save()"
        class="save-btn mt-4"
        color="primary"
        >Guardar</v-btn
      >
    </v-card-actions>

    <v-dialog persistent v-if="addDialog" v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title
          >{{ selectedCard ? "Editar tarjeta" : "Nueva tarjeta" }}
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            v-if="!loading"
            absolute
            @click="
              addDialog = false;
              card = {};
            "
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="formCard" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  outlined
                  :loading="loading"
                  :rules="[rules.number]"
                  rounded
                  :disabled="selectedCard ? true : false"
                  @keypress="maxLength($event, card.bin)"
                  hide-details
                  type="number"
                  label="Bin"
                  v-model.number="card.bin"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  outlined
                  :loading="loading"
                  :rules="[rules.required]"
                  :items="cards"
                  rounded
                  hide-details
                  item-text="name"
                  item-value="value"
                  label="Tipo de tarjeta"
                  v-model="card.brand"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-select
                  outlined
                  :loading="loading"
                  :rules="[rules.required]"
                  :items="types"
                  rounded
                  hide-details
                  item-text="name"
                  item-value="type"
                  label="Tipo de tarjeta"
                  v-model="card.type"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  outlined
                  :loading="loading"
                  :rules="[rules.required]"
                  rounded
                  hide-details
                  label="Nombre del producto"
                  v-model="card.product"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn
            @click="selectedCard ? saveEditedCard() : saveCard()"
            height="50"
            block
            color="primary"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
    
    <script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";

export default {
  name: "edit-bank",
  props: ["selectedItem"],
  components: {},
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: false,
      search : "",
      selectedCard: null,
      bines: [],
      card: {
        bin: null,
        brand: "",
        type: "",
        product: "",
      },
      addDialog: false,

      cards: [
        { name: "Visa", value: "VISA" },
        { name: "MasterCard", value: "MASTERCARD" },
        { name: "American Express", value: "AMEX" },
        { name: "Discover", value: "DISCOVER" },
        { name: "Diners Club", value: "DINERS CLUB" },
        { name: "JCB", value: "JCB" },
        { name: "UnionPay", value: "UNION PAY" },
      ],
      types: [
        { name: "Crédito", type: "credit" },
        { name: "Débito", type: "debit" },
      ],
      headers: [
        {
          text: "Bin",
          value: "bin",
        },

        {
          text: "Marca",
          value: "brand",
        },
        {
          text: "Producto",
          value: "product",
        },

        {
          text: "Tipo",
          value: "type",
        },

        {
          value: "options",
          align: "end",
          width : "400px"
        },
      ],
      item: {
        name: "",
      },
      rules: {
        required: (value) => !!value.length || "Obligatorio",
        number: (value) => !!value || "Obligatorio",
      },
    };
  },

  computed: {
    ...mapState(["user"]),
    getBines() {
      return this.bines.filter((e) => !e.deleted);
    },
  },

  methods: {
    async update() {
      if (!this.$refs.form.validate()) {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        return;
      }

      this.loading = true;

      await db.collection("banks").doc(this.selectedItem[".key"]).update({
        name: this.item.name,
        modifiedAt: new Date(),
        modifiedBy: this.user[".key"],
      });

      for (let i = 0; i < this.bines.length; i++) {
        const item = this.bines[i];

        let ref = db
          .collection("banks")
          .doc(this.selectedItem[".key"])
          .collection("cards");

        if (!item[".key"]) {
          await ref.add({
            brand: item.brand,
            type: item.type,
            product: item.product,
            bin: item.bin,
            createdAt: new Date(),
            createdBy: this.user[".key"],
          });
        } else {
          await ref.doc(item[".key"]).update({
            brand: item.brand,
            type: item.type,
            product: item.product,
            modifiedAt: new Date(),
            modifiedBy: this.user[".key"],
          });
        }

        if (item.deleted && item[".key"]) {
          ref.doc(item[".key"]).delete();
        }
      }

      this.loading = false;
      this.$emit("success");
    },

    async save() {
      if (!this.$refs.form.validate()) {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        return;
      }

      this.loading = true;

      let bankRef = await db.collection("banks").add({
        name: this.item.name,
        createdAt: new Date(),
        createdBy: this.user[".key"],
      });

      for (let i = 0; i < this.bines.length; i++) {
        const item = this.bines[i];

        if (!item.deleted) {
          await db.collection("banks").doc(bankRef.id).collection("cards").add({
            brand: item.brand,
            type: item.type,
            product: item.product,
            bin: item.bin,
            createdAt: new Date(),
            createdBy: this.user[".key"],
          });
        }
      }

      this.loading = false;
      this.$emit("success");
    },
    deleteBin(item) {
      this.bines = this.bines.map((e) => {
        if (e.bin == item.bin) {
          e.deleted = true;
        }

        return e;
      });
    },

    saveEditedCard() {
      if (!this.$refs.formCard.validate()) {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        return;
      }

      this.card.modifiedAt = new Date();
      this.card.modifiedBy = this.user[".key"];
      this.card.isEdited = true;

      this.bines = this.bines.map((e) => {
        if (e.bin == this.card.bin) {
          e.brand = this.card.brand;
          e.type = this.card.type;
          e.product = this.card.product;
        }
        return e;
      });

      this.addDialog = false;
    },

    saveCard() {
      if (!this.$refs.formCard.validate()) {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        return;
      }
      let exist = this.bines.find((e) => e.bin == this.card.bin);

      if (exist) {
        this.snackbarText = "El bin ingresado ya existe.";
        this.snackbar = true;
        return;
      }

      this.card.createdBy = this.user[".key"];
      this.card.createdAt = new Date();
      this.card.isNew = true;
      this.bines.unshift(this.card);
      this.addDialog = false;
    },

    newCard() {
      this.card = {
        bin: null,
        brand: "",
        type: "",
        product: "",
      };

      this.selectedCard = null;
      this.addDialog = true;
    },

    editCard(item) {
      this.addDialog = true;
      this.selectedCard = item;

      this.card = Object.assign({}, item);
    },
    maxLength(evt, field) {
      if (field && field.toString().length >= 6) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  async mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);

      await this.$binding(
        "bines",
        db
          .collection("banks")
          .doc(this.selectedItem[".key"])
          .collection("cards")
      );
    }
  },
};
</script>
    